.navItem {
  margin-right: 2rem;
  cursor: pointer;
  position: relative;
}

.underline {
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 15px;
  background: #01b0d3;
  opacity: 0.85;
}
